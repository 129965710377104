export const useFoodFamilyStore = defineStore('food-family', () => {
  const $api = useApi();

  const allFamilies = ref([]);
  const activeFamily = ref();

  async function fetchAllFamilies() {
    const { data: families } = await $api.get(`/food/family/all`);
    if (families) {
      allFamilies.value = families;
    }
  }

  async function fetchDetailedFamily(familyId: string) {
    const { data: family } = await $api.get(`/food/family/${familyId}`);
    activeFamily.value = family;
  }

  async function updateFoodFamily(
    familyId: string,
    data: Record<string, string>,
  ) {
    const { data: familyUpdated } = await $api.put(
      `/food/family/${familyId}`,
      data,
    );
    if (familyUpdated) {
      const familyToUpdateIndex = allFamilies.value.findIndex(
        (n) => n.id === familyId,
      );
      allFamilies.value[familyToUpdateIndex] = {
        ...allFamilies.value[familyToUpdateIndex],
        ...data,
      };
    }
  }

  return {
    allFamilies,
    activeFamily,
    fetchAllFamilies,
    fetchDetailedFamily,
    updateFoodFamily,
  };
});
